#filters-card input{
    display: none;
}
#filters-card{
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 5;
}
#filters-card.hide{
  left: -250px;
}

#filters{
  width: 250px;
  background-color: var(--white);
  border-radius: 0 20px 20px 0;
  box-shadow: 0 0 25px rgba(171, 156, 157, 0.35);
  padding: 15px;
}
.filter button{
  margin-right: 15px;
}
#filters-card .toggle-arrow{
  width: 35px;
  height: 35px;
  background-color: hsl(201, 81%, 95%);
  border-radius: 10px ;
  line-height: 35px;
  position: absolute;
  right: 10px;
  top: 24px;
  cursor: pointer;
}
#filters-card.hide .toggle-arrow{
  right: -35px;
}

#filters-card.hide .toggle-arrow svg{
  transform: rotate(180deg);
}



.filter label{
  background-color: #C7C7CC;
  color: var(--grey);
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(171, 156, 157, 0.35);
  /* height: 45px; */
  padding: 10px 15px;
  margin: 10px;
  cursor: pointer;
}

.filter input[type="radio"]:checked+label{
  background-color: var(--red);
  color: var(--white);
  box-shadow: 0 3px 10px rgba(236, 29, 39, 0.35);
}
